import * as Sentry from '@sentry/react';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios, { isCancel } from 'axios';
import { useCallback, useEffect, useState } from 'react';

export type DataResult<T> = { data: T; error: undefined; loading: false };
export type ErrorResult = {
  data: undefined;
  error: AxiosError;
  loading: false;
};
export type LoadingResult = {
  data: undefined;
  error: undefined;
  loading: true;
};

export type UseAxiosResult<T> = DataResult<T> | ErrorResult | LoadingResult;
export type UseAxiosResponse<T> = [
  result: UseAxiosResult<T>,
  refresh: () => void,
];

export const useAxios = <T>(
  url: string | undefined | false,
  axiosOptions?: AxiosRequestConfig,
  condition?: boolean,
): UseAxiosResponse<T | undefined> => {
  const [response, setResponse] = useState<UseAxiosResult<T | undefined>>({
    data: undefined,
    error: undefined,
    loading: true,
  });
  const [instance, setInstance] = useState(Date.now());

  const refresh = useCallback(() => setInstance(Date.now()), []);

  useEffect(() => {
    if (!url || condition === false) {
      setResponse({ data: undefined, error: undefined, loading: false });
      return;
    }

    const controller = new AbortController();
    setResponse({ data: undefined, error: undefined, loading: true });
    axios
      .request<T>({
        method: 'GET',
        url,
        ...axiosOptions,
        signal: controller.signal,
      })
      .then((response) => {
        setResponse({ data: response.data, error: undefined, loading: false });
        return;
      })
      .catch((error: unknown) => {
        if (isCancel(error)) {
          Sentry.addBreadcrumb({
            data: {
              instance,
              url,
            },
            level: 'info',
            message: 'Request cancelled',
          });
          return;
        }

        const { toJSON } = error as { toJSON: () => string };
        console.error(
          'Axios Error',
          toJSON ? toJSON.apply(error) : JSON.stringify(error),
          error,
        );
        if (error instanceof Error) {
          Sentry.captureException(error);
        } else {
          Sentry.captureMessage(
            `Axios (non-)Error: ${JSON.stringify(error)}`,
            'error',
          );
        }
        setResponse({
          data: undefined,
          error: error as AxiosError,
          loading: false,
        });
      });
    return () => controller.abort();
  }, [url, axiosOptions, condition, instance]);

  return [response, refresh];
};
