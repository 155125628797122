import type { OutlinedInputProps, TextFieldProps } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import type { FormikProps } from 'formik';

type Props<ID, T> = TextFieldProps &
  OutlinedInputProps & { id: ID; formik: FormikProps<T> };

export function FormikTextField<
  ID extends string,
  T extends { [key in ID]?: string },
>(props: Props<ID, T>): JSX.Element {
  const {
    formik,
    fullWidth,
    id,
    label,
    margin,
    onInvalid,
    onKeyDown,
    onKeyUp,
    size,
    variant,
    ...textProps
  } = props;

  return (
    <FormControl
      disabled={formik.isSubmitting || props.disabled}
      error={
        (formik.touched[props.id] && Boolean(formik.errors[props.id])) ||
        props.error
      }
      fullWidth={fullWidth ?? true}
      margin={margin}
      onInvalid={onInvalid}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      size={size}
      variant={variant}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        {...textProps}
        id={id}
        disabled={formik.isSubmitting || props.disabled}
        value={formik.values[props.id]}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        name={props.name ?? props.id}
        fullWidth={fullWidth}
        label={label}
        size={size}
      />
      <FormHelperText id={`${id}-helper-text`}>
        {formik.errors[props.id]?.toString() || props.helperText}
      </FormHelperText>
    </FormControl>
  );
}
