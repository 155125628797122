import type { FormikErrors } from 'formik';
import type { Contract, Issue } from 'validata';
import { isObject, isValue } from 'validata';

const issueToString = (issue: Issue): string => {
  switch (issue.reason) {
    case 'max': {
      if (Number(issue.info?.max))
        return `Too large, must be ${Number(issue.info?.max)} or less`;
      return 'Too large';
    }
    case 'min': {
      if (Number(issue.info?.min))
        return `Too small, must be ${Number(issue.info?.min)} or more`;
      return 'Too small';
    }
    case 'max-length': {
      if (Number(issue.info?.max))
        return `Too long, must be ${Number(issue.info?.max)} chars or less`;
      return 'Too long';
    }
    case 'min-length': {
      if (Number(issue.info?.min))
        return `Too short, must be ${Number(issue.info?.min)} chars or more`;
      return 'Too short';
    }
    case 'regex':
      return 'Invalid value';
    case 'not-defined':
      return 'Must have a value';
    case 'incorrect-type':
      return 'Invalid value';
    case 'incorrect-format':
      return issue.info?.expectedFormat
        ? `Expected format: ${String(issue.info?.expectedFormat)}`
        : 'Invalid format';
  }
  return issue.reason;
};

export const issuesToFormik = <T>(issues: Issue[]): FormikErrors<T> => {
  const errors = issues.reduce(
    (acc, issue) => {
      const key = issue.path.join('.');
      const error = acc[key] ?? [];
      error.push(issueToString(issue));
      return {
        ...acc,
        [key]: error,
      };
    },
    {} as Record<string, string[] | undefined>,
  );

  return Object.keys(errors).reduce(
    (acc, key) => ({
      ...acc,
      [key]: errors[key]?.join(', '),
    }),
    {} as FormikErrors<T>,
  );
};

export const formikCheck = <T>(
  check: Contract<T>,
  value: unknown,
): Promise<FormikErrors<T>> => {
  const result = isObject(check).process(value);
  if (isValue(result)) {
    return Promise.resolve({});
  }

  return Promise.resolve(issuesToFormik(result.issues));
};
